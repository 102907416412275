<template>
    <div>
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <h2 class="content-header-title float-start mb-0">
              Picnic Package
            </h2>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-2">
          <div class="col-12">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb float-end">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">Picnic Package</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card card-congratulation-medal">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="d-flex col-12">
                      <div class="float-start col-6">
                        <div class="input-group input-group-merge w-50">
                          <span class="input-group-text" id="basic-addon-search2"
                            ><vue-feather type="search"></vue-feather></span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search..."
                            aria-label="Search..."
                            aria-describedby="basic-addon-search2"
                            v-model="search"
                            @keyup="searchData"
                          />
                        </div>
                      </div>
                      <!-- data-bs-toggle="modal"
                      data-bs-target="#modals-slide-in" -->
                      <div class="float-end col-6">
                        <button
                          type="button"
                          class="btn btn-gradient-primary float-end"
                          @click="add"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <section id="ajax-datatable">
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-datatable">
                            <table
                              class="dt-column-search table table-responsive"
                            >
                              <thead>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                  <td>{{ index + paginationData.from }}</td>
                                  <td>{{ li.pack_name }}</td>
                                  <td>{{ li.adult_price }}</td>
                                  <td>{{ li.child_price }}</td>
                                  <td>{{ tConvert(li.check_in_time) }}</td>
                                  <td>{{ tConvert(li.check_out_time) }}</td>
                                  <td>{{ li.notes }}</td>
                                  <td>
                                    <span class="badge badge-light-success" v-for="(item, item_index) in li.package_amenity" :key="item_index">{{ item.amenity_master.amenity_name }}</span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-primary rounded"
                                          @click="edit(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="edit" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      class="d-flex justify-content-start align-items-center mb-75"
                                    >
                                      <span class="me-75">
                                        <div
                                          class="avatar bg-light-danger rounded"
                                          @click="deleteDes(li.id)"
                                        >
                                          <div class="avatar-content">
                                            <vue-feather type="trash-2" size="15"></vue-feather>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-show="list.length === 0">
                                  <td colspan="10" class="text-center">No Data Found</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <template v-for="head in header" :key="head">
                                    <th>{{ head }}</th>
                                  </template>
                                </tr>
                              </tfoot>
                            </table>
                            <div class="row d-flex">
                              <div class="col-12 d-flex">
                                <div class="col-6 showing_entry">
                                  Showing {{ paginationData.from }} to
                                  {{ paginationData.to }} of
                                  {{ paginationData.total }} entries
                                </div>
                                <div class="col-6">
                                  <Pagination
                                    :data="paginationData"
                                    @pagination-change-page="getResults"
                                    show-disabled="true"
                                    limit="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <teleport to="#modal">
      <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
        <div class="modal-dialog">
          <form
            class="add-new-user modal-content pt-0"
            method="post"
            @submit.prevent="submitForm"
          >
            <input type="hidden" name="id" :v-modal="id" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="[isShow='', isDisplay='none']"
            >
              ×
            </button>
            <div class="modal-header mb-1">
              <h4 class="modal-title" id="exampleModalLabel">
                {{ modalName }} Picnic Package Master
              </h4>
            </div>
            <div class="modal-body flex-grow-1">
               <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Package Name</label>
                <input
                    type="text" class="form-control" name="pack_name" v-model="form.pack_name" placeholder="Package Name" @keyup="
                    form.pack_name.length > 0
                        ? [(packNameError = 'none'), (this.disabled = false)]
                        : (packNameError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: packNameError}" > Please enter Package name. </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Price For Adult</label>
                <input
                    type="number" class="form-control" name="adult_price" v-model="form.adult_price" id="adult_price" step="any" placeholder="Price For Adult" @keyup="
                    form.adult_price.toString().length > 0
                        ? [(adultPriceError = 'none'), (disabled = false)]
                        : (adultPriceError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: adultPriceError}" > Please enter price for Adult. </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Price For Child</label>
                <input
                    type="number" class="form-control" name="child_price" v-model="form.child_price" id="child_price" step="any" placeholder="Price For Child" @keyup="
                    form.child_price.toString().length > 0
                        ? [(childPriceError = 'none'), (disabled = false)]
                        : (childPriceError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: childPriceError}" > Please enter price for Child. </div>
              </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Check In Time</label>
                <input
                    type="time" class="form-control" name="check_in_time" step="2" v-model="form.check_in_time" id="check_in_time" placeholder="Check in Time" @keyup="
                    form.check_in_time.toString().length > 0
                        ? [(checkInTimeError = 'none'), (disabled = false)]
                        : (checkInTimeError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: checkInTimeError}" > Please enter Check In Time. </div>
             </div>
              <div class="mb-1">
                <label class="form-label" for="basic-addon-name">Check Out Time</label>
                <input
                    type="time" class="form-control" name="check_out_time" step="2" v-model="form.check_out_time" id="check_out_time" placeholder="Check out Time" @keyup="
                    form.check_out_time.toString().length > 0
                        ? [(checkOutTimeError = 'none'), (disabled = false)]
                        : (checkOutTimeError = 'block')
                    "/>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: checkOutTimeError}" > Please enter Check Out Time. </div>
             </div>
             <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Notes</label>
              <textarea class="form-control" name="notes" v-model="form.notes" placeholder="Notes" @keyup="
                  form.notes.length > 0
                    ? [(notesError = 'none'), (this.disabled = false)]
                    : (notesError = 'block')
                "></textarea>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: notesError}" > Please enter Address. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-amenity">Amenity</label>
              <Select2
                  v-model="form.amenity"
                  :options="amenity"
                  :settings="{
                    multiple: true,
                    placeholder: 'Choose Amenity',
                  }"
              />
            </div>
              <button
                type="submit"
                class="btn btn-primary me-1 data-submit"
                :disabled="disabled"
              >
                Submit
              </button>
              <button
                type="reset"
                class="btn btn-outline-secondary"
                :disabled="disabled"
                data-bs-dismiss="modal"
                @click="[isShow='', isDisplay='none']"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import axios from "axios";
  import LaravelVuePagination from "laravel-vue-pagination";
  import Select2 from "vue3-select2-component";
  import Form from 'vform';
  
  export default {
    name: "PicnicPackage",
    components: {
      Pagination: LaravelVuePagination,
      Select2
    },
    data() {

      return {
        form: new Form({
            id: null,
            pack_name: "",
            adult_price: "",
            child_price: "",
            check_in_time: "",
            check_out_time: "",
            notes: "",
            amenity: [],
        }),
        response: "",
        disabled: false,
        header: [],
        list: [],
        amenity: [],
        modalName: "Add",
        paginationData: {},
        search: null,
        packNameError: "none",
        adultPriceError: "none",
        childPriceError: "none",
        checkInTimeError: "none",
        checkOutTimeError: "none",
        notesError: "none",
        isShow: "",
        isDisplay: "none",
        token: localStorage.getItem('auth_token')
      };
    },
    async mounted() {
      this.header = [
        "Sr. no.",
        "Package Name",
        "Price For Adult",
        "Price For Child",
        "Check In Time",
        "Check Out Time",
        "Notes",
        "Amenities",
        "Action",
      ];
      this.getAmenity();
      this.getResults();
    },
    methods: {
      async getAmenity() {
        await axios.get(process.env.VUE_APP_API_URL+"/amenity_master", 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.amenity = response.data.data;
          });
      },
      async add() {
        (this.form.id = null),
        (this.form.pack_name = ""),
        (this.form.adult_price = ""),
        (this.form.child_price = ""),
        (this.form.check_in_time = ""),
        (this.form.check_out_time = ""),
        (this.form.notes = ""),
        (this.form.amenity = []),
        (this.response = "");
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async getResults(page = 1) {
        await axios.get(process.env.VUE_APP_API_URL+"/picnic_package_master?page=" + page, 
          {
            headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "Authorization": `Bearer ${this.token}`
            }
          })
          .then((response) => {
            this.paginationData = response.data.data;
          });
        this.list = this.paginationData.data;
      },
      async submitForm() {
        this.disabled = true;
        if (this.form.pack_name.length === 0) {
          this.packNameError = "block";
        } else if (this.form.adult_price.toString().length === 0) {
          this.adultPriceError = "block";
        } else if (this.form.child_price.toString().length === 0) {
          this.childPriceError = "block";
        } else if (this.form.check_in_time.length === 0) {
          this.checkInTimeError = "block";
        } else if (this.form.check_out_time.length === 0) {
          this.checkOutTimeError = "block";
        } else if (this.form.notes.length === 0) {
          this.notesError = "block";
        } else {
          if (this.form.id) {
            await this.form.put(process.env.VUE_APP_API_URL+"/picnic_package_master/" + this.form.id,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            })
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.isShow = ""
                this.isDisplay = "none"
                this.disabled = false;
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
          } else {
            await this.form.post(process.env.VUE_APP_API_URL+"/picnic_package_master",
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              })
              .then((response) => {
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                  this.isShow = ""
                  this.isDisplay = "none"
                  this.disabled = false;
                  this.getResults();
                } else {
                  this.$toast.error(response.data.error?.[0]);
                  this.disabled = false;
                }
              })
              .catch((error) => {
                this.$toast.error(error);
                this.disabled = false;
              });
          }
        }
      },
      deleteDes(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .delete(process.env.VUE_APP_API_URL+"/picnic_package_master/" + id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                }
              )
              .then(response => {
                this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      },
      async edit(id) {
        this.tags = [];
        this.packNameError = "none";
        this.adultPriceError = "none";
        this.childPriceError = "none";
        this.checkInTimeError = "none";
        this.checkOutTimeError = "none";
        this.notesError = "none";
        this.disabled = false

        const output = await axios.get(process.env.VUE_APP_API_URL+"/picnic_package_master" +(id ? "/" + id : ""),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.token}`
                  },
                });
        this.form.id = output.data.data?.id;
        this.form.pack_name = output.data.data?.pack_name;
        this.form.adult_price = output.data.data?.adult_price;
        this.form.child_price = output.data.data?.child_price;
        this.form.check_in_time = output.data.data?.check_in_time;
        this.form.check_out_time = output.data.data?.check_out_time;
        this.form.notes = output.data.data?.notes;
        this.form.amenity = output.data.data?.package_amenity.map(({ amenity_id }) => amenity_id);
        
        this.modalName = "Edit";
        this.isShow = "show"
        this.isDisplay = "block"
      },
      async searchData() {
        await axios
          .post(process.env.VUE_APP_API_URL+"/picnic_package_masters/search",
            {
              search: this.search,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${this.token}`
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.list = response.data.data.data;
              this.isShow = ""
              this.isDisplay = "none"
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
      tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice (1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
        }   
    },
  };
  </script>
